@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -o-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    -o-box-shadow: $box-shadow;
    -ms-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}
@mixin transition($transition){
    -webkit-transition:$transition;
    -moz-transition:$transition;
    -o-transition:$transition;
    -ms-transition:$transition;
    transition:$transition;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
  @include transform(rotate($deg));
}
// scale
@mixin scale($scale) {
	 @include transform(scale($scale));
} 
// translate
@mixin translate ($x, $y) {
   @include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
   @include transform(skew($x, $y));
}
//transform origin
@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
	     -o-transform-origin: $origin;
	    -ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
          transform-origin: $origin;
}


//responsive
@mixin bp-xlarge {
    @media (max-width: 1480px) {
        @content;
    }
}

@mixin bp-large {
    @media (max-width: 1199px) {
        @content;
    }
}

@mixin bp-medium {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin bp-small {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin bp-lsmall {
    @media (max-width: 480px) {
        @content;
    }
}


//extra
@mixin cont_in{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
}
@mixin image{
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        max-height: 100%;
}
@mixin button{
    margin-top: 15px;
    padding: 0px;
    text-align: center;
    a{
    background: $primary-blue;
    color: $white;
    text-decoration: none;
    text-transform: uppercase;
    padding: 10px 33px;
    font-size: 17px;
        @include border-radius(4px);
    font-weight: 600;
    margin-right: 5px;
    box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        i{
            font-size: 22px;margin-right: 10px;vertical-align: middle;
        }
    }
}