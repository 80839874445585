@import 'base/_base.scss';
@import 'base/_mixins.scss';
@import 'base/_variables.scss';
@import 'base/_reset.scss';
@import 'modules/_typography.scss';
.header-top {
    background: #01559D;
    .left {
        p {
            padding: 0;
            color: #fff;
            font-size: 14px;
            margin: 0;
            position: relative;
            padding-left: 20px;
                     @include bp-small{text-align: center;}
            @include bp-lsmall{text-align: center;}
            &:before {
                position: absolute;
                top: 9px;
                left: 0;
                border: 2px solid #fff;
                width: 9px;
                height: 9px;
                content: "";
                border-radius: 100px;
                box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.48);
                @include bp-small{display: none;}
                @include bp-lsmall{display: none;}
            }
        }
    }
    .right {
        text-align: right;
                    @include bp-small{text-align: center;}
        ul {
            margin: 0px;
            li {
                color: #fff;
                i {
                    margin-right: 5px;
                }
                a{
                    color:#fff;
                    text-decoration: none;
                }
            }
        }
    }
}

.header-lower {
    background: #fff;
    padding: 0px;
    position: relative;
    .logo {
            position: relative;
    height: 62px;
        @include bp-medium{height: 54px;}
    background: orange;
    z-index: 4;
        img {
               position: absolute;
    top: 5px;
            @include bp-medium{top: 11px;}
            @include bp-small{top:0px;}
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    left: 0;
    right: 0;
        }
        &:after {
            content: "";
    position: absolute;
    right: -15%;
    top: 0%;
    width: 75px;
    height: 100%;
    background: orange;
    z-index: -1;
    transform: skewX(-30deg);
                    @include bp-small{display: none;}
        }
        &:before {
               content: "";
    position: absolute;
    right: 100%;
    top: 0%;
    width: 1000px;
    height: 100%;
    background: orange;
                    @include bp-small{display: none;}
        }
    }
    .menu {
        background: #fff;
        text-align: right;
        @include bp-small{text-align: center;}
        ul {
            margin-bottom: 0;
            padding: 12px 0px;
            li {
                padding: 0px 10px;
                a {
                    position: relative;
                    font-family: para;
    font-size: 15px;
    text-decoration: none;
    color: #000;
                        text-transform: capitalize;
                }
                .active{
                    position: relative;
    border-bottom: 4px solid orange;
    padding-bottom: 5px;
                    @include bp-small{padding: 0px;}
                    &:after{
                             position: absolute;
    bottom: -17px;
    left: 14px;
    border-color: orange transparent transparent transparent;
    border-style: solid;
    border-width: 7px;
    content: "";
                    }
                }
            }
        }
    }
    .shadow{
            width: 100%;
    height: 12px;
    background: url(../images/shadow.png);
    background-size: 1px 12px;
    position: absolute;
    z-index: 1;
    }
}

//banner
.banner {
    .carousel {
        position: relative;
        .overlay{
            position: absolute;
            top:0;left: 0;
            right: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.3);
        }
        img {
            width: 100%;
            margin: 0 auto;
        }
        .comp-name {
            position: absolute;
            top: 17%;
            @include bp-large{left: 6%;}
            @include bp-small{top: 22%;}
            @include bp-lsmall{top:0%;padding: 5px 10px 0px 10px;}
            left: 0;
            right: 0;
            text-align: left;
            h1 {
                font-size: 46px;
                @include bp-large{font-size: 34px;line-height: 46px;}
    font-family: font4;
    line-height: 60px;
    text-shadow: none;
    text-transform: capitalize;
                @include bp-small{font-size: 22px;line-height: 26px;margin: 0;}
                span{
                    color: #ff8404;
                }
            }
        }
        a {
            background: transparent;
        }
        .carousel-control {
           width: 32px;
    height: 70px;
    background: rgba(0, 0, 0, 0.38);
    line-height: 68px;
    top: 40%;
    opacity: 1;
            @include bp-small{width: 15px;background: rgba(0, 0, 0, 0.38);line-height: 57px;}
            i {
                font-size: 17px;
            }
        }
        .comp-buttons{
            margin-top: 10px;
            a{
                background: #ff8404;
    color: #fff;
    text-decoration: none;
    text-shadow: none;
    padding: 7px 20px;
    font-family: font2;
    font-size: 15px;
    margin: 0px 5px;
    border-radius: 3px;
                @include bp-small{padding: 5px 16px;}
            }
        }
    }
}

//about us
.abt-btn{
    text-align: left !important;
    @include bp-medium{text-align: center !important;}
}
.about_us {
    h2 {
        text-align: left;
    font-family: font4;
    font-size: 30px;
    line-height: 23px;
    margin-bottom: 0px;
        @include bp-medium{margin: 0px;font-size: 23px;text-align: center;}
        span {
            color: orange;
    text-transform: capitalize;
    margin-top: 10px;
    font-weight: 600;
        }
    }
    .image {
text-align: center;
                    @include bp-medium{display: none;}
        img {
            width: 100%;
        }
    }
    .para {
        margin-top: 30px;
        @include bp-medium{margin: 0px;border:none;}
        border-left: 4px solid #f1f1f1;
        p {
             span:first-child{
                color:deepskyblue;
                font-weight: 600;
                font-family: font2;
            }
             span:last-child{
                color:orange !important;
                font-weight: 600;
                font-family: font2;
            }
        }
        h6{
            background: #f9f9f9;
    color: #666666;
    padding: 15px 20px;
    font-size: 15px;
    font-family: font6;
            line-height: 24px;
            i{
                color: #ff8404;
    font-size: 27px;
            }
        }
    }
}
.clear{
    clear: both;
}

//button
.button {
    text-align: center;
    a {
       background: #ff8404;
    color: #fff;
    font-family: font6;
    font-size: 15px;
    text-decoration: none;
    padding: 0 25px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    z-index: 1;
    line-height: 33px;
        &:after{
            position: absolute;
    top: 0;
   right: -27px;
    width: 88px;
    height: 100%;
    background: rgba(0, 0, 0, 0.10);
    content: "";
    transform: skewX(38deg);
        }
    }
}

//slogan
.slogan {
    position: relative;
    background: url(../images/ban-5.jpg);
   height: 180px;
    @include bp-medium{height: 140px;}
    @include bp-small{height: 100px;}
    text-align: center;
    margin-top: 0px;
    background-attachment: fixed;
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 191, 255, 0.5);
    }
    h3 {
               color: #fff;
    font-family: font6;
    font-size: 30px;
    padding-top: 40px;
        @include bp-medium{font-size: 25px;padding-top: 16px;}
        @include bp-small{font-size: 19px;padding-top: 0;line-height: 28px;margin-top: 12px;}
    line-height: 42px;
        i {
                    font-size: 32px;
    vertical-align: super;
            @include bp-small{font-size: 26px;}
        }
    }
}
.slogan2 h3{
    padding-top:20px;
}
//heading
.heading {
    text-align: center;
    h2 {
            text-align: center;
    font-family: font1;
    font-size: 21px;
        @include bp-small{font-size: 16px;}
        margin: 20px 0px;
        @include bp-medium{margin: 10px 0px 0px 0px;}
        margin-bottom: 30px;
        text-transform: uppercase;
        position: relative;
        display: inline-block;
        padding-bottom: 5px;
        &:before {
               position: absolute;
    bottom: -2px;
    left: 0;
    border-bottom: 3px solid orange;
    content: "";
    width: 50%;
        }
        &:after {
            position: absolute;
    bottom: -16px;
    left: 16px;
    border-color: orange transparent transparent transparent;
    border-style: solid;
    border-width: 7px;
    content: "";
        }
    }
}

//choose us
.choose {
    padding: 30px 0px;
    padding-top: 12px;
    .choose_block {
        background: #FF9800;
        padding: 19px;
        text-align: center;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        border-radius: 3px;
        position: relative;
        height: 165px;
        @include bp-medium{margin-bottom: 15px;}
        @include bp-small{margin-bottom: 10px;margin-top: 10px;}
        &:before {
            position: absolute;
            top: -8px;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 100px;
            content: "";
        }
        &:after {
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 20px;
            background: #fff;
            border-radius: 100px;
            content: "";
        }
        i {
            font-size: 80px;
            color: #fff;
        }
        span {
            display: block;
    font-size: 18px;
    text-transform: uppercase;
    font-family: font2;
    margin: 8px 0px;
    color: #fff;
    line-height: 27px;
    font-weight: 600;
            @include bp-large{font-size: 16px;line-height: 22px;}
        }
    }
    .ch-color {
        background: deepskyblue;
    }
}

//products
.products {
    padding: 30px 0px;
    @include bp-medium{padding: 0px;}
    .prod_block {
       margin: 20px 0px;
    position: relative;
    padding: 0;
//    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
    border: 1px solid #ddd;
    border-bottom: none;
        &:before{
            position: absolute;
    top: 17px;
    left: -2px;
    content: "";
    height: 35px;
    width: 4px;
    z-index: 1;
    background: #FF9800;
        }
        .image {
            position: relative;
    height: 270px;
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                width: 100%;
                height: 100%;
                transition: all 1s ease;
                @include bp-small{max-width: 100%;max-height: 100%;width:initial;height: initial;}
                @include bp-lsmall{max-width: 100%;max-height: 100%;}
            &:hover{
                transform: scale(1.1) rotate(3deg);
                transition: all 1s ease;
            }
            }
        }
        ul {
            margin: 0;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            border-top: 3px solid deepskyblue;
            .main-prod {
                 height: 54px !important;
    line-height: 50px !important;
                &:after{
                    top:17px !important;
                }
            }
            .left {
               background: deepskyblue;
    text-align: center;
    color: #fff;
    font-size: 26px;
    height: 79px;
    line-height: 78px;
    position: relative;
                outline: 1px solid #fff;
    outline-offset: -4px;
                @include bp-large{display: none;}
                &:after{
                        position: absolute;
    top: 30px;
    right: -20px;
    content: "";
    border-color: #ff9800 transparent transparent transparent;
    border-style: solid;
    border-width: 10px;
    transform: rotate(-90deg);
                }
                i {
                    color: #fff;
                    padding: 10px;
                    @include bp-large{padding: 0;}
                }
            }
            .right {
                p {
                    color: #000;
    font-family: font6;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 4px;
    padding-bottom: 0px;
//    margin: 2px;
    margin: 0;
                    span {
                        color: #FF9800;
                        display: block;
                    }
                    @include bp-large{height: 77px;}
                    @include bp-small{height: initial;}
                }
            }
        }
    }
    .button {
                margin-top: 40px;
    }
}

//other products

.other{
    padding: 30px 0px;
    .other-prod{
        position: relative;
    overflow: hidden;
    height: 300px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.12);
            margin-top: 20px;
        img{
            position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
              @include bp-small{max-width: 100%;max-height: 100%;width:initial;height: initial;}
                @include bp-lsmall{max-width: 100%;max-height: 100%;}
            transition: all 1s ease;
            &:hover{
                transform: scale(1.1) rotate(3deg);
                transition: all 1s ease;
            }
        }
            h3{
   text-align: center;
    color: #fff;
    font-family: font6;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0;
    line-height: 24px;
    background: rgba(0, 191, 255, 0.75);
    padding: 5px 0px;
    margin: 0;
    font-size: 17px;
    text-transform: capitalize;
                border: none;
            }
    }
    .button{
            margin-top: 35px;
    }
}

//footer

.footer{
    background: #171717;
    padding: 0px;
    .footer_address{
        ul{
            li{
                color:#7f7f7f;
                font-family: font6;
                position: relative;
    padding: 10px 0px;
                address{
                           display: initial;
    margin: 0px;
    position: absolute;
    top: 0;
                }
                i{
                    color:#fff;
                    margin-right: 10px;
                }
                &:nth-child(2){
                    padding-bottom: 0px;
                }
                &:nth-child(3){
                    padding: 0px;
                }
            }
        }
    }
    .foot_block{
        h3{
            color: #fff;
    font-size: 15px;
    text-transform: uppercase;
    font-family: font2;
    border-bottom: 1px solid #7f7f7f;
    position: relative;
    padding-bottom: 6px;
    display: inline-block;
            &:after{
                   position: absolute;
    top: 21px;
    left: 0;
    background: deepskyblue;
    height: 3px;
    content: "";
    width: 50px;
            }
        }
        p{
            padding: 0px;
    font-family: font6;
    font-size: 15px;
    line-height: 24px;
            color:#7f7f7f;
        }
        ul{
            font-family: font2;
            margin-top: 10px;
            li{
                a{
                    color:#7f7f7f;
                    font-size: 15px;
                    text-decoration: none;
                    font-family: font6;
                    i{
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .working{
        ul{
            border:1px solid #7f7f7f;
            margin-top: 10px;
            li{
                text-align: center;
                color:#7f7f7f;
                font-family: font6;
            }
        }
    }
}
  .footer_strip{
        border-top:1px solid #4e4e4e;
      background: #171717;
        .left{
            p{
                padding: 9px 0px;
    font-family: font6;
    font-size: 15px;
    color: #7f7f7f;
                @include bp-small{text-align: center;}
                span{
                    color: deepskyblue;
                    font-weight: 600;
                }
            }
        }
        .right{
            p{
               padding: 9px 0px;
   font-family: font6;
    font-size: 15px;
    color: #7f7f7f;
                text-align: right;
                a{
                    color: deepskyblue;
                    font-weight: 600;
                    text-decoration: none;
                }
                @include bp-small{padding: 0px;
                text-align: center;}
            }
        }
    }



//get social

.get_social{
    background: #f7f7f7;
    padding: 0px;
    p{
     color: #777;
    font-size: 17px;
    padding: 10px 0px;
    font-family: font2;
    text-align: center;
    line-height: 27px;
    font-weight: 600;
        @include bp-medium{font-size: 15px;line-height: 22px;}
        @include bp-small{font-size: 14px;line-height: 21px;}
    }
    ul{
        text-align: center;
        padding: 30px 0px;
        li{
            font-size: 37px;
            a{
                color:#00aced;
                i{
                    font-size: 30px;
                }
            }
            &:nth-child(2) a{
                color:#4e64b5;
            }
             &:nth-child(3) a{
                color:#f44336;
            }     
            &:nth-child(4) a{
                color:#00bcd4;
            }
        }
    }
}

//about block


.about_banner{
    position: relative;
    img{
        width:100%;
        margin: 0 auto;
    }
}
.about_company_name{
    position: absolute;
    top: 30%;
    @include bp-medium{top:17%;}
    left: 10%;
    h1{
       color: #fff;
    font-size: 38px;
        @include bp-medium{font-size: 31px;}
    margin: 10px 0px;
    margin-bottom: 7px;
        @include bp-small{font-size:26px;}
    }
    span{
        color: #fff;
    font-size: 23px;
    font-family: font2;
          @include bp-small{display: none;}
    }
}
.about_us{
    padding: 10px 0px;
    .imagess{
        text-align: center;
        @include bp-medium{display: none;}
        img{
            width:150px;
        }
    }
    .text{
            padding: 80px 0px;
        padding-top: 30px;
        @include bp-medium{padding-bottom: 0px;}
        p{
            
        }
        h2{
            border-left: 4px solid #ccc;
    color: #01559d;
    margin: 5px 0px;
    padding: 2px 10px;
            @include bp-medium{border: none;}
        }  
    }
}

//products page

.prod1_section,.prod2_section{
    padding: 20px 0px;
    h3{
        margin: 5px 0px;
    text-transform: capitalize;
    font-family: font4;
    color: #01559d;
    border-left: 4px double #ddd;
    padding-left: 8px;
        @include bp-small{font-size: 20px;}
        @include bp-lsmall{font-size: 20px;}
    }
    p{
        padding: 5px 0px;
    font-family: font6;
    color: #777777;
    font-size: 16px;
        padding-left: 10px;
        @include bp-small{font-size: 14px;line-height: 20px;}
        span{
            color: orange;
    text-transform: capitalize;
            font-family: font1;
        }
    }
}

//contact page

.contact_inner_section{
    .left,.right,.map{
        h3{
            margin: 15px 0px;
    font-family: font4;
    font-size: 20px;
    border-left: 4px solid orange;
    padding-left: 8px;
            @include bp-small{font-size: 14px;margin: 5px 0px;}
        }
        p{
            padding: 10px 0px;
            @include bp-small{font-size: 13px;}
        }
    }
    .left{
        ul{
            padding: 0px;
            li{
                position: relative;
    padding: 10px 0px 10px 70px;
                .icon-box{
                                      background: #01559D;
    position: absolute;
    width: 50px;
    height: 50px;
    line-height: 50px;
    top: 8px;
    left: 0;
    right: 0;
    text-align: center;
                    span{
                                            color: #fff;
                        font-size: 20px;
                    }
                    @include bp-small{width: 40px;height: 40px;line-height: 43px;}
                }
                h4{
                   font-family: font1;
    font-size: 14px;
    margin: 3px 0px;
                }
                .mail{
                    display: block;
    line-height: 20px;
                }
                address{
                    margin-top: 6px;
    margin-bottom: 0px;
                }
            }
        }
    }
    .map{
        iframe{
            width:100%;
            height: 250px;
        }
    }
    .right{
        form{
            text-align: center;
            margin-top: 18px;
            button{
                background: orange;
                color: #fff;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.23);
                padding: 5px 20px;
                border-radius: 3px;
                font-family: font6;
    font-size: 16px;
                &:focus{
                    outline: none;
                }
            }
            .form-group{
                input{
                    border:1px solid #ddd;
                    border-radius: 0px;
                    box-shadow: none;
                }
                textarea{
                    width:100%;
                    box-shadow: none;
                     border:1px solid #ddd;
                    padding-left: 10px;
                    resize: none;
                }
                &:focus{
                    input,textarea{
                        border:1px solid #ddd;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}


//service page

.service{
    h2{
        margin: 0px;
    }
}
.service_desc{
    padding: 15px 15px;
    @include bp-small{font-size: 13px;line-height: 22px;}
        span{
            color: #01559d;
    font-family: font4;
        }
}

//serror class

.js-error{
    color: red;
    padding: 0px;
}
.js-success{
    color: green;
    padding: 0px;
}


//fancy box
.gallery{
    padding: 20px 0px;
    .fancy_sec{
height: 250px;
    margin-top: 20px;
    box-shadow: 0px 2px 2px #aaa;
    padding: 0;
        position: relative;
        overflow: hidden;
        &:hover .gallery-overlay{
             transform: translateY(0px);
             transition: all 1s ease;
        }
       .gallery-overlay{
            position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to left top, rgba(255, 126, 95, 0.78) , rgba(254, 180, 123, 0.73) );
    width: 100%;
    height: 100%;
    transition: all 1s ease;
           transform: translateY(-300px);
                               i{
                    position: absolute;
    top: 43%;
    left: 0;
    text-align: center;
    color: #fff;
    font-size: 37px;
    text-align: center;
    right: 0;
            }
        }
    a{
        img{
           width: 100%;
    height: 100%;
    margin: auto;
                background: #ecf7ff;
    padding: 8px;
              @include bp-small{max-width: 100%;max-height: 100%;width:initial;height: initial;}
                @include bp-lsmall{max-width: 100%;max-height: 100%;}
        }
    }
}
}


//responsive

//mobile menu

.menu_mobile{
    font-family: font4;
    text-align: center;
    i{
        font-size: 21px;
    margin: 10px 0px;
    color: orange;
        margin-left: 8px;
    }
    @include bp-xlarge{display: none;}
    @include bp-large{display: none;}
    @include bp-medium{display: none;}
    @include bp-small{display: block;}
    @include bp-lsmall{display: block;}
}
.menu ul{
        @include bp-small{display: none;position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 4;
    padding: 7px 0px !important;
    background: #f4f4f4;text-align: center;}
    li{
        @include bp-small{width:initial;}
        @include bp-lsmall{width:100%;text-align: center;}
    }
}