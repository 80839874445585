body{
    font-family: 'para';
    font-size: 14px;
    line-height: 28px;
    margin:0;padding: 0;
}
p{
    line-height: 25px;
    text-align: justify;
    font-size: 14px;
    padding: 20px 0px;
    color: #777;
    font-family: sans-serif;
    margin: 0px;
}