$primary-color:#393185;
$secondary-color:#07cb79;
$heading:#303030;

$gray:#666;
$white:#fff;
$black:#000;




