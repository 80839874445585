//padding
.p-0{
    padding: 0px;
}
.p-10{
    padding: 10px;
}
.p-t-5{
    padding-top:5px;
}
.p-t-10{
    padding-top:10px;
}
.p-t-15{
    padding-top:15px;
}
.p-t-20{
    padding-top:20px;
}


//margin
.m-0{
    margin:0px;
}
.m-t-5{
    margin-top:5px;
}
.m-t-10{
    margin-top:10px;
}
.m-t-15{
    margin-top:15px;
}
.m-t-20{
    margin-top:20px;
}

//text-align

.text-right{
    text-align:right;
}
.text-left{
    text-align:left;
}
.text-center{
    text-align:center;
}
