
@font-face {
  font-family: 'font1';
  src:  url('../fonts/font1.woff2') format('woff2');
}
@font-face {
  font-family: 'font2';
  src:  url('../fonts/font2.woff2') format('woff2');
}
@font-face {
  font-family: 'font4';
  src:  url('../fonts/font4.woff2') format('woff2');
}
@font-face {
  font-family: 'font6';
  src:  url('../fonts/font6.woff2') format('woff2');
}
@font-face {
  font-family: 'para';
  src:  url('../fonts/para.ttf') format('truetype');
}


